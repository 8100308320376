import React from "react"

import "./TermsAndServiceComponent.scss"

const TermsAndServiceComponent = () => {
  return (
    <div className="terms-and-service">
      <h1>Terms and Conditions</h1>
      <div className="terms-and-service__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
    </div>
  )
}

export default TermsAndServiceComponent
